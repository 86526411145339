<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    :single-select="true"
    item-key="id"
    show-select
    hide-default-footer
    :loading="loading"
    loading-text="Загрузка... Пожалуйста подождите"
    fixed-header
    @click:row="rowClick"
  >
    <template v-slot:item.birthDate="{ item }">
      <span>{{ item.birthDate | dateLocalFilter }}</span>
    </template>
  </v-data-table>
</template>

<script>
import fetch from "@/utils/fetch";
import js2vm from "@/utils/js2vm";
import ctx from "@/ctx";
import dateIsoFilter from "../../filters/dateIso.filter";
import vm2js from "../../utils/vm2js";
import dictFilter from "../../filters/dict.filter";

let Item = function(d) {
  let self = this;

  if (d.cityLabel) {
    d.cityLabel = d.cityKey;
  }

  self.cityLabel2vm = function(val) {
    return dictFilter("INS_CITY", val);
  };

  self.birthDate2vm = function(val) {
    return val ? dateIsoFilter(val) : null;
  };

  js2vm(self, d);
};

export default {
  props: {
    typeKey: String
  },
  data: () => ({
    currentPage: 0,
    totalPages: 0,
    loading: false,
    selected: [],
    headers: [
      {
        text: "Фамилия",
        align: "start",
        value: "lastName"
      },
      { text: "Имя", sortable: false, value: "firstName" },
      { text: "Отчество", sortable: false, value: "middleName" },
      { text: "Дата рождения", sortable: false, value: "birthDate" },
      { text: "ИИН", value: "iin" },
      {
        text: "Город",
        value: "cityLabel"
      },
      { text: "Моб.1", sortable: false, value: "phoneMobile1" },
      { text: "Моб.2", sortable: false, value: "phoneMobile2" },
      { text: "Тел. рабочий", sortable: false, value: "phoneWork" },
      { text: "Тел. домашний", sortable: false, value: "phoneHome" }
    ],
    items: [],
    new_: undefined
  }),
  watch: {
    selected: function(val) {
      if (val.length === 0) {
        return (this.$parent.item = this.new_ ? this.new_ : undefined);
      } else {
        this.new_ = undefined;
        let item = new Item(vm2js(val[0]));
        item.person = vm2js(item.person4List);
        this.$parent.item = item;
      }
    }
  },
  methods: {
    add() {
      this.new_ = { person: {} };
      this.selected = [];
    },
    close() {
      this.new_ = undefined;
      this.selected = [];
    },
    async save() {

      let data = vm2js(this.$parent.item);

      data.typeKey = this.typeKey;
      const res = await fetch.post("/api/persons/save", data);
      //


      if (res) {
        ctx.src.persons.assign(this.$parent.item.person).then(() => {
          if (this.$parent.item.id) {
            let found = this.items.find(i => {
              return i.id === res;
            });
            if (found) found = new Item(res);
          } else {
            data.id = res;

            this.items.unshift(new Item(data));

            this.items.find(i => i.id === res).specialization = ctx.src.dict[
              "INS_DOCTOR_TYPE"
            ].items.find(i => i.key === data.specializationKey).value;
          }


        });

        let alreadyExistentItem = this.items.find(i => i.id === data.id);
        if (alreadyExistentItem) {
          this.items.find(i => i.id === data.id).specialization = ctx.src.dict[
            "INS_DOCTOR_TYPE"
          ].items.find(i => i.key === data.specializationKey).value;
        }


        // let obj = this.items.find(item => item.id === res);

        //

        //

        //
        //
        //
        //
        // Object.assign(obj, this.$parent.item);
        // if (data.specialization) {
        //   this.$parent.item.specialization = data.specialization;
        // }
        //
        //

        //
        // this.items.push()
        // let alreadyExistentItem = this.items.find(item => item.id === data.id);
        // if (alreadyExistentItem) {
        //   Object.assign(alreadyExistentItem, data);
        // } else {
        //   this.items.push(data);
        // }
        this.close();
      }
    },
    rem() {
      this.items.splice(0, 1);
    },
    search(searchText) {
      this.items = [];
      this.selected = [];
      this.currentPage = 0;
      const params = {
        page: this.currentPage,
        predicate: searchText
      };
      this.fetchItems(params);
    },
    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          this.fetchItems(params);
        }
      }
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },
    async fetchItems(params) {
      this.loading = true;
      let res;
      if (!params.predicate)
        res = await fetch.get(
          "/api/persons/get-list?page=" +
            params.page +
            "&typeKey=" +
            this.typeKey
        );
      else
        res = await fetch.get(
          "/api/persons/search?predicate=" +
            params.predicate +
            "&typeKey=" +
            this.typeKey +
            "&page=" +
            params.page
        );
      if (res.first) this.totalPages = res.totalPages;
      res.content.forEach(element => {
        this.items.push(new Item(element));
      });


      this.loading = false;
    }
  },
  mounted() {
    if (this.typeKey === "5") {
      this.headers.push({
        text: "Специализация",
        sortable: true,
        value: "specialization"
      });
    }
    this.$parent.personsList = this;
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", this.handleScroll);
    this.fetchItems({ page: 0 });
  }
};
</script>
