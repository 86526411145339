<template>
  <v-form ref="form" v-model="valid" lazy-validation style="overflow: auto">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.lastName"
            label="Фамилия"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.firstName"
            label="Имя"
            :rules="requiredRules"
            required
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.middleName"
            label="Отчество"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.iin"
            label="ИИН"
            :rules="requiredRules"
            :counter="12"
            required
            type="number"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="4">
          <v-autocomplete
            v-model="item.specializationKey"
            :items="ctx.src.dict['INS_DOCTOR_TYPE'].items"
            :label="'Специализация'"
            item-text="value"
            item-value="key"
            clearable
          >
          </v-autocomplete>
        </v-col>

        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="item.birthDate"
            label="Дата рождения"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="item.sexKey"
            :items="ctx.src.dict['INS_SEX'].items"
            :label="'Пол'"
            item-text="value"
            item-value="key"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.phoneMobile1"
            label="Мобильный телефон 1"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.phoneMobile2"
            label="Мобильный телефон 2"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.phoneHome"
            label="Домашний телефон"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="item.phoneWork"
            label="Рабочий телефон"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="item.cityKey"
            :items="ctx.src.dict['INS_CITY'].items"
            :label="'Город'"
            item-text="value"
            item-value="key"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="9">
          <v-text-field v-model="item.address" label="Адрес"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    vm: Object,
    byContract: { type: Boolean, default: false }
  },
  data: () => ({
    ctx,
    requiredRules: [v => !!v || "Обязательное поле"],
    valid: false,
    insuredByContract: []
  }),
  watch: {
    item: function() {
      this.$refs.form.validate();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    isA_Doctor() {
      return this.item.typeKey === "5";
    }
  },
  mounted() {
    this.vm.insured = this;
    this.validate();


  },
  unmounted() {



  }
};
</script>
