<template>
  <div class="view-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="searchText"
          hide-details
          append-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          @keydown.enter="search"
          @click:append="search"
        ></v-text-field>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn
          v-if="rightFilter('d')"
          icon
          :disabled="!item || !item.id"
          @click="rem"
        >
          <v-icon>remove</v-icon>
        </v-btn>
        <v-spacer />
      </v-toolbar>
      <Master :typeKey="'5'"></Master>
    </div>
    <div v-if="item" class="view">
      <v-toolbar class="my-bar">
<!--        <v-btn v-if="rightFilter('w')" icon @click="save">
          <v-icon>save</v-icon>
        </v-btn>-->
        <SaveButton
        :handler="save"
        ></SaveButton>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <Detail v-bind:item="item" :vm="this"></Detail>
    </div>
  </div>
</template>

<script>
import Master from "../components/person/Master";
import Detail from "../components/person/Detail";
import rightFilter from "../filters/right.filter";
import fetch from "../utils/fetch";
import SaveButton from "@/components/ButtonFacade";
// import ctx from "@/ctx";
export default {
  data: () => ({
    rightFilter,
    item: undefined,
    detailShown: false,
    searchText: undefined
  }),
  watch: {
    item: function(val) {
      this.detailShown = val ? true : false;
    }
  },
  methods: {
    add() {
      this.personsList.add();
    },
    async rem() {
      this.selected = [];
      console.log("rem");
      console.log(this.item.id);
      const result = await fetch.post("/api/persons/delete?id=" + this.item.id);
      this.personsList.items = this.personsList.items.filter(
        item => item.id != this.item.id
      );
      console.log(result);
    },
    search() {
      this.personsList.search(this.searchText);
    },
    save() {
      if (this.insured.valid) this.personsList.save();
    },
    close() {
      this.personsList.close();
    }
  },
  components: {
    SaveButton,
    Detail,
    Master
  },
  mount() {
    console.log(this.personList);
  }
};
</script>
